import React, { Component } from 'react';
import { connect } from 'react-redux'

import Logger from './../../util/Logger';
import Localstore from "./../../util/Localstore";

import { dispatch } from './../../redux/store';

// ------------------------ actions
import { validateToken, clearToken, saveToken, login, getUser, activeAccount } from './../../redux/actions/User';

import { getUser as getStateUser } from './../../redux/selectors/User';

import { STATUS_CHECKING, STATUS_LOADING_APP, STATUS_LOGIN, STATUS_SELECT_ACCOUNT, STATUS_VALIDATING } from '../../data/Status';
import Configuration from '../../../config/Configuration';
import { ACCOUNT_BACKOFFICE, ACCOUNT_DEMAND_SIDE } from '../../data/AccountType';
import { ENVIRONMENT_DEV } from '../../data/environment';

import './Login.css'
import NormalSelectCmp from '../../visual/NormalSelect';
import LocalStore from './../../util/Localstore';

class Login extends Component {
  constructor(props) {
    super(props);

    this._logger = new Logger("Login");

    this.state = {
      loading: false,
      disabled: false,
      msg: "",

      // values: checking, validating, login
      status: STATUS_CHECKING
    }
  }

  // ------------------------- PRIVATE ------------------------
  restaurarAuth = (pApp) => {
    let vThat = this;
    const auth = Localstore.getToken(pApp);
    if (auth) {
      // if (window.location.pathname !== PATH.Home) {
      //   window.location.href = PATH.Home;
      //   return true;
      // }
      this.setState({
        status: STATUS_VALIDATING
      });

      dispatch(validateToken(auth)).then((pData) => {
        let { success } = pData;
        if (success) {
          this.setState({
            status: STATUS_LOADING_APP
          });
          // dispatch(saveToken(auth));
          dispatch(getUser(auth)).then((pUser) => {
            // go to app according user's version
            if (Configuration.env === ENVIRONMENT_DEV) {
              if (pUser.data.accounttype === ACCOUNT_BACKOFFICE) {
                window.location.href = Configuration.fexpressbackoffice.url + "dev/" + Configuration.fexpressbackoffice.loginService + "/" + auth;
              } else {
                let vApp = "dev/";
                if (pUser.data.accounttype === ACCOUNT_DEMAND_SIDE) {
                  vApp = "d/dev/";
                }
                window.location.href = Configuration.webapp.url + vApp + Configuration.webapp.loginService + "/" + auth;
              }
            } else {
              if (pUser.data.accounttype === ACCOUNT_BACKOFFICE) {
                window.location.href = Configuration.fexpressbackoffice.url + (pUser.data.appver < 100 ? ("" + pUser.data.appver).padStart(3, '0') : pUser.data.appver) + "/";
              } else {
                let vApp = "";
                if (pUser.data.accounttype === ACCOUNT_DEMAND_SIDE) {
                  vApp = "d";
                }
                // window.location.href = Configuration.webapp.url + "dev/";
                window.location.href = Configuration.webapp.url + vApp + (pUser.data.appver < 100 ? ("" + pUser.data.appver).padStart(3, '0') : pUser.data.appver) + "/";
              }
            }

          });
        } else {
          dispatch(clearToken(pApp));
          vThat.setState({
            status: STATUS_LOGIN
          });
        }

      })
      // dispatch(validarSesion())
      // dispatch(recuperarCliente())
    } else {
      dispatch(clearToken(pApp));
      this.setState({
        status: STATUS_LOGIN
      });
    }
    return false;
  }
  // ------------------------- EVENTS -------------------------
  onChange = (pEvnt) => {
    pEvnt.preventDefault();

    let vState = {};
    vState[pEvnt.target.name] = pEvnt.target.value;

    if (pEvnt.target.name === "user") {
      vState.disabled = !vState[pEvnt.target.name] || vState[pEvnt.target.name].length === 0 || !this.state.passw || this.state.passw.length === 0;
    }
    if (pEvnt.target.name === "passw") {
      vState.disabled = !vState[pEvnt.target.name] || vState[pEvnt.target.name].length === 0 || !this.state.user || this.state.user.length === 0;
    }

    vState.error = "";

    this.setState(vState);
  }

  onChangeAccountCmp = (pName, pValue) => {
    // pEvnt.preventDefault();

    this.setState({
      account: pValue,
      accountid: pValue.value,
      error: ""
    });
  }

  onSelectAccount = (pEvnt) => {
    let vThat = this;
    pEvnt.preventDefault();

    if (this.state.loading) return;

    this.setState({
      loading: true,
      error: ""
    });

    let vAccount = this.state.accounts.find(x => x.id === vThat.state.accountid);

    dispatch(activeAccount(this.state.token, vThat.state.accountid)).then(pResponse => {

      if (Configuration.env === "dev") {
        LocalStore.saveToken(vThat.state.app, pResponse.data);
        // window.location.href = Configuration.webapp.url + "login/" + vTkn;
        if (vAccount.type === ACCOUNT_BACKOFFICE) {
          window.location.href = Configuration.fexpressbackoffice.url + "dev/" + Configuration.fexpressbackoffice.loginService + "/" + pResponse.data + "___" + vAccount.id;
        } else {
          let vApp = "";
          if (vAccount.type === ACCOUNT_DEMAND_SIDE) {
            vApp = "d/dev/";
          }
          window.location.href = Configuration.webapp.url + vApp + Configuration.webapp.loginService + "/" + pResponse.data + "___" + vAccount.id;
        }

      } else {
        // write new token
        LocalStore.saveToken(vThat.state.app, pResponse.data);
        dispatch(saveToken(null, pResponse.data))
        if (vAccount.type === ACCOUNT_BACKOFFICE) {
          window.location.href = Configuration.fexpressbackoffice.url + (this.state.user.appverbck < 100 ? ("" + vThat.state.user.appverbck).padStart(3, '0') : vThat.state.user.appverbck) + "/";
        } else {
          // window.location.href = Configuration.webapp.url + "dev/";
          let vApp = "";
          if (vAccount.type === ACCOUNT_DEMAND_SIDE) {
            vApp = "d";
          }
          window.location.href = Configuration.webapp.url + vApp + (vThat.state.user.appver < 100 ? ("" + vThat.state.user.appver).padStart(3, '0') : vThat.state.user.appver) + "/";
        }

      }
      // vThat.setState({
      //   loading: false,
      //   error: ""
      // });

    });

  }

  onLogin = (pEvnt) => {
    pEvnt.preventDefault();
    let vThat = this;

    this._logger.debug("Sending Login.. ", this.state);

    if (this.state.loading) return;

    if (!this.state.user || this.state.user.length === 0 ||
      !this.state.passw || this.state.passw.length === 0) {
      this.setState({
        loading: false,
        error: "",
        disabled: true
      });
      return;
    }

    this.setState({
      loading: true,
      error: ""
    });

    dispatch(login(this.state.user, this.state.passw, this.state.app)).then((pData) => {

      console.log("LOGIN -> after login", pData);
      if (pData.success) {
        vThat.setState({
          // msg: "loading..."
          status: STATUS_LOADING_APP
        });

        // dispatch(getAllCreatives(pData.data));
        let vTkn = pData.data;
        dispatch(getUser(vTkn)).then((pUser) => {

          // User is associated to many accounts 
          if (pUser.data.accounts.length > 1) {
            vThat.setState({
              accounts: pUser.data.accounts,
              accountid: pUser.data.accounts[0].id,
              account: { value: pUser.data.accounts[0].id, label: pUser.data.accounts[0].name },
              status: STATUS_SELECT_ACCOUNT,
              token: vTkn,
              user: pUser.data,
              loading: false
            })
            return;
          }
          let vAccountDto = pUser.data.accounts[0];

          if (Configuration.env === "dev") {
            // window.location.href = Configuration.webapp.url + "login/" + vTkn;
            if (pUser.data.accounttype === ACCOUNT_BACKOFFICE) {
              window.location.href = Configuration.fexpressbackoffice.url + "dev/" + Configuration.fexpressbackoffice.loginService + "/" + vTkn + "___" + vAccountDto.id;
            } else {
              let vApp = "";
              if (pUser.data.accounttype === ACCOUNT_DEMAND_SIDE) {
                vApp = "d/dev/";
              }
              window.location.href = Configuration.webapp.url + vApp + Configuration.webapp.loginService + "/" + vTkn + "___" + vAccountDto.id;
            }

          } else {
            dispatch(saveToken(null, vTkn));
            if (pUser.data.accounttype === ACCOUNT_BACKOFFICE) {
              window.location.href = Configuration.fexpressbackoffice.url + (pUser.data.appver < 100 ? ("" + pUser.data.appver).padStart(3, '0') : pUser.data.appver) + "/";
            } else {
              // window.location.href = Configuration.webapp.url + "dev/";
              let vApp = "";
              if (pUser.data.accounttype === ACCOUNT_DEMAND_SIDE) {
                vApp = "d";
              }
              window.location.href = Configuration.webapp.url + vApp + (pUser.data.appver < 100 ? ("" + pUser.data.appver).padStart(3, '0') : pUser.data.appver) + "/";
            }
          }


        });

      } else {
        let vMsg = "";
        switch (pData.msg) {
          case "user_not_exists":
            vMsg = "User does not exist.";
            break;
          case "user_without_account":
            vMsg = "You have not account assigned.";
            break;
          default:
            vMsg = "error unknown. [" + pData.data + "]";
        }
        this.setState({
          loading: false,
          error: vMsg
        });
      }

    });

  }

  componentDidUpdate(prevProps) {
    console.log("LOGIN -> Update: ", this.props.user);
    if (!prevProps.user.token && this.props.user.token) {
      this.props.history.push("/");
      return null;
    }
  }

  componentDidMount() {
    console.log("LOGIN -> Mount: ", this.props.user);

    this.restaurarAuth();

  }

  render() {

    let vError = ""

    // if (!this.props.user.token && window.location.pathname !== PATH.User.Login) {
    //   window.location.href = PATH.User.Login;
    //   return null;
    // }

    if (this.state.error) {
      vError = <> <br /> <div className="form-group login-error-row">
        <span className="error-msg">{this.state.error}</span>
      </div> </>;
    }

    let vMsg = ""
    if (this.state.msg) {
      vMsg = <>
        <br />
        <div className="form-group login-info-row">
          <span className="info-msg">{this.state.msg}</span>
        </div>
      </>;
    }

    let vClassBtn = "btn btn-primary " + (this.state.loading || this.state.disabled ? "disabled" : "");

    return (
      <>
        <div className=" login">
          <div className="four columns login-panel">
            <form>

              <div className="login_panel_top">
                Welcome to the Forebase Platform!
              </div>

              {this.state.status === STATUS_LOGIN &&
                <>
                  <div className="form-group login-row">
                    <label htmlFor="in-user">Email</label>&nbsp;&nbsp;
                    <input type="email" className="form-control" id="in-user" name="user" aria-describedby="emailHelp" onChange={this.onChange} />
                  </div>
                  <br />
                  <div className="form-group login-row">
                    <label htmlFor="in-passw">Password</label>&nbsp;&nbsp;
                    <input type="password" className="form-control" id="in-passw" name="passw" onChange={this.onChange} />
                  </div>
                  <br />
                  <div className="form-group">
                    <input type="submit" className={vClassBtn} disabled={this.state.loading || this.state.disabled} onClick={this.onLogin} value="LOGIN" />
                  </div>
                  {vError}
                </>
              }

              {this.state.msg && vMsg}

              {this.state.status === STATUS_VALIDATING &&
                <>
                  <br />
                  <div className="form-group status-msg">
                    <b>Validating your Creadentials...</b>
                  </div>
                </>
              }

              {this.state.status === STATUS_CHECKING &&
                <>
                  <br />
                  <div className="form-group status-msg">
                    <b>Checking if exists Creadentials...</b>
                  </div>
                </>
              }

              {this.state.status === STATUS_LOADING_APP &&
                <>
                  <br />
                  <div className="form-group status-msg">
                    <b>Loading Application...</b>
                  </div>
                </>
              }

              {this.state.status === STATUS_SELECT_ACCOUNT &&
                <>
                  <div className="form-group login-row">
                    <label htmlFor="in-accountid">Sites</label>&nbsp;&nbsp;
                    {/* <select name="accountid" value={this.state.accountid} id="in-accountid" onChange={this.onChangeAccount} >
                      {this.state.accounts.map(x => {
                        return <option value={x.id}>{x.name}</option>;
                      })}
                    </select> */}
                    <NormalSelectCmp
                      NormSelConst={{
                        name: "account",
                        items: this.state.accounts.map(x => {
                          return { value: x.id, label: x.name };
                        }),
                        selected: this.state.account,
                        callback: this.onChangeAccountCmp
                      }
                      }
                    >

                    </NormalSelectCmp>
                  </div>
                  <br />
                  <div className="form-group">
                    <input type="submit" className={vClassBtn} disabled={this.state.loading} onClick={this.onSelectAccount} value="Enter" />

                  </div>
                </>
              }

            </form>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    user: getStateUser(state)
  }
}
export default connect(mapStateToProps)(Login);

